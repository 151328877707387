import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import CollaborationRequestModal from 'containers/CollaborationRequestModal';
import Filters from './Filters';
import InitiativeCard from 'components/InitiativeCard';
import MainLayout from 'components/MainLayout';

import { Tabs } from 'antd';

import {
  isAfterNow,
  guessIsWorkingHours,
} from 'utils/date';
import useNow from 'utils/useNow';
import useOrganizations from 'utils/useOrganizations';

import {
  requestCollaborativeInitiatives,
  requestCollaboration,
  requestInitiatives
} from 'actions/api';

import './style.less';

const Collaboration = ({
  intl,
  collaborations,
  profile,
  requestCollaborativeInitiatives,
  requestCollaboration,
  initiatives,
  requestInitiatives,
  taxonomies,
}) => {
  const {
    organization,
  } = useOrganizations();

  useEffect(() => {
    requestCollaborativeInitiatives(organization.slug);
  }, [
    organization.slug,
    requestCollaborativeInitiatives,
  ]);

  useEffect(() => {
    requestInitiatives(organization.slug);
  }, [
    organization.slug,
    requestInitiatives,
  ]);

  const [initiativeToRequest, setInitiativeToRequest] = useState(null);

  const now = useNow();

  const t = intl.messages;

  const {
    items: allInitiatives,
  } = collaborations;

  const {
    data: admin_profile,
  } = profile;

  const activeInitiatives = useMemo(
    () => allInitiatives.filter(({ end_time }) => isAfterNow(end_time)),
    [ // eslint-disable-line react-hooks/exhaustive-deps
      allInitiatives,
      now,
    ]);

  const pastInitiatives = useMemo(
    () => allInitiatives.filter(({ end_time }) => !isAfterNow(end_time)),
    [ // eslint-disable-line react-hooks/exhaustive-deps
      allInitiatives,
      now,
    ]);

  const submitRequestModal = useCallback((email, cohorts, isWorkingHours) => {
    requestCollaboration(
      organization.slug,
      initiativeToRequest.slug,
      (email || '').trim(),
      cohorts,
      isWorkingHours,
    );
    setInitiativeToRequest(null);
  }, [
    initiativeToRequest,
    organization.slug,
    requestCollaboration,
  ]);

  const showWorkingHours = (organization.config || {}).consider_working_hours;

  return (
  <MainLayout
    errorMessage={intl.formatMessage({id: 'error_boundary_collaborations_message'})}
  >
      <Tabs defaultActiveKey="available" renderTabBar={(props) =>
        <section>
          <h1 className='collaborationTitle'>{t.collaborations_explore}</h1>
          {/*
          <section className='collaborationsTabs'>
            { props.panes.map((tab) => (
              !tab ? null :
              <span
                className={'tab ' + (props.activeKey === tab.key ? 'active' : '')}
                onClick={() => props.onTabClick(tab.key)}>
                  {tab.props.tab}
              </span>
            ))}
          </section>
          */}
        </section>
      }>
        <Tabs.TabPane tab={ t.available } key="available">
          <div className="Whitespace20"></div>
          <Filters
            data={activeInitiatives}
            pastData={pastInitiatives}
            sdgs={taxonomies.sdgs}
            alreadyPassedText={t.already_passed_collaborations}
            emptyCardText={t.emptyCollaborations}
            renderChildren={(initiatives, searchText) => (
              <section className='initiativeContainer'>
                {initiatives.map(initiative => (
                  <InitiativeCard
                    from={'collaboration'}
                    activeNow={true}
                    organization={organization}
                    searchText={searchText}
                    requestCollaboration={() => setInitiativeToRequest(initiative)}
                    {...initiative}
                  />
                ))}
              </section>
            )}
          />
          <CollaborationRequestModal
            visible={initiativeToRequest}
            autoApproved={
              initiativeToRequest
                ? (initiativeToRequest.auto_approved || false)
                : false
            }
            defaultEmail={(admin_profile || {}).email || ''}
            defaultInitiativeCohorts={[]}
            defaultIsWorkingHours={guessIsWorkingHours(
              new Date((initiativeToRequest || {}).start_time),
              new Date((initiativeToRequest || {}).end_time),
            )}
            startTime={(initiativeToRequest || {}).start_time}
            endTime={(initiativeToRequest || {}).end_time}
            onClose={() => setInitiativeToRequest(null)}
            onSave={submitRequestModal}
            considerWorkingHours={showWorkingHours}
          />
        </Tabs.TabPane>
        {/*
        <Tabs.TabPane tab={ t.requested_participation } key="requested_participation">
          { pendingColaborationRequests.length > 0
            ? <section className='initiativeContainer'>
              {
                pendingColaborationRequests.map((initiative, index) => (
                  <InitiativeCard
                    from={'collaboration'}
                    activeNow={true}
                    organization={organization}
                    requestCollaboration={() => setInitiativeToRequest(initiative)}
                    {...initiative}
                  />
                ))
              }
              </section>
            : <span className='noPending'>{t.participants_noPendingValidations}</span>
          }
        </Tabs.TabPane>
        */}
      </Tabs>
    </MainLayout>
    )
}

const mapStateToProps = ({
  collaborations,
  profile,
  initiatives,
  taxonomies,
}) => ({
  collaborations,
  profile,
  initiatives,
  taxonomies,
});

export default injectIntl(connect(mapStateToProps, { requestCollaborativeInitiatives, requestCollaboration, requestInitiatives })(Collaboration));
