import React, { useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { Button, Modal } from 'antd';

import {
  formatDateLong,
  guessIsWorkingHours,
} from 'utils/date';

import CollaborationRequestModal from 'containers/CollaborationRequestModal';
import { usePermissionList } from 'components/PermissionSwitch';

const Collaboration = ({
  intl,
  organization_name,
  organization_logo_small,
  collaboration_status,
  auto_approved_collaborations,
  collaboration_updated_at,
  cohorts = [],
  is_working_hours,
  start_time,
  end_time,
  defaultEmail = '',
  onRequest,
  onUpdate,
  onCancel,
  onStop,
  reason,
  organization,
  collaborative,
  canceled_at,
  isCanceled,
}) => {
  const t = intl.messages;
  const showCancelConfirm = useCallback(() => {
    Modal.confirm({
      title: t.collaboration_cancel_request_title,
      content: t.collaboration_cancel_request_desc,
      okText: t.collaboration_cancel_request,
      cancelText: t.close,
      onOk: onCancel,
      onCancel: () => {},
    });
  }, [ t, onCancel ])

  const [ requestModalShown, setRequestModalShown ] = useState(false);
  const [ updateModalShown, setUpdateModalShown ] = useState(false);

  const openRequestModal = useCallback(() => {
    setRequestModalShown(true);
    setUpdateModalShown(false);
  }, [ setRequestModalShown, setUpdateModalShown ]);

  const openUpdateModal = useCallback(() => {
    setRequestModalShown(false);
    setUpdateModalShown(true);
  }, [ setRequestModalShown, setUpdateModalShown ]);

  const closeModals = useCallback(() => {
    setRequestModalShown(false);
    setUpdateModalShown(false);
  }, [ setRequestModalShown, setUpdateModalShown ]);

  const submitRequestModal = useCallback((email, cohorts, isWorkingHours) => {
    onRequest((email || '').trim(), cohorts, isWorkingHours);
    setRequestModalShown(false);
  }, [ onRequest, setRequestModalShown ]);

  const submitUpdateModal = useCallback((email, cohorts, isWorkingHours) => {
    onUpdate((email || '').trim(), cohorts, isWorkingHours);
    setUpdateModalShown(false);
  }, [ onUpdate, setUpdateModalShown ]);

  const permissionList = usePermissionList();
  const isAllowed = permissionList.has('can_manage_collaborations');

  const showWorkingHours = (organization.config || {}).consider_working_hours;

  if(collaborative && isCanceled) {
    return (
      <section className='actionCollaboration'>
        <span className='actionCollaboration-title canceled'>{t.collaboration_stopped}</span>
        <span className='actionCollaboration-text'>
          { !!canceled_at && formatDateLong(canceled_at, intl) }
        </span>
      </section>
    )
  }

  switch(collaboration_status) {
    case 'pending':
      return (
        <>
          <section className='actionCollaboration'>
            <span className='actionCollaboration-title pending'>{t.collaboration_request_sent}</span>
            <span className='actionCollaboration-text'>{formatDateLong(collaboration_updated_at, intl)}</span>
          </section>
          { !isAllowed ? null :
            <Button type='default' onClick={showCancelConfirm} className='actionCollaboration-button'>
              <img src='/images/icon-cancel-collab.svg' alt={t.cancel_collab}/>
              <span>{t.cancel_collab}</span>
            </Button>
          }
        </>
      );
    case 'accepted':
      return (
        <>
          <section className='actionCollaboration'>
            <span className='actionCollaboration-title accepted'>{t.collaborating}</span>
            <span className='actionCollaboration-text'>{formatDateLong(collaboration_updated_at, intl)}</span>
          </section>
          { !isAllowed ? null :
            <Button type='default' onClick={openUpdateModal} className='actionCollaboration-button'>
              <img src='/images/icon-manage.svg' alt={t.collaboration_manage_groups}/>
              <span>{t.collaboration_manage_groups}</span>
            </Button>
          }
          <CollaborationRequestModal
            visible={updateModalShown}
            defaultEmail={defaultEmail}
            defaultInitiativeCohorts={cohorts || []}
            defaultIsWorkingHours={is_working_hours || false}
            startTime={start_time}
            endTime={end_time}
            onClose={closeModals}
            onSave={submitUpdateModal}
            firstTime={false}
            autoApproved={auto_approved_collaborations}
            considerWorkingHours={showWorkingHours}
          />
        </>
      );
    case 'rejected':
      return (
        <>
          <section className='actionCollaboration'>
            <span className='actionCollaboration-title rejected'>{t.collaboration_rejected}</span>
            <span className='actionCollaboration-text'>{formatDateLong(collaboration_updated_at, intl)}</span>
          </section>
        </>
      );
    default:
      return (
        <>
          { !isAllowed ? null :
            <Button type='primary' onClick={openRequestModal} className='actionCollaboration-button'>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path fill="#FFF" fill-rule="nonzero" d="M16.293 7.612c.368 0 .775.31.775.692.005 2.34-.776 4.673-2.342 6.353-3.323 3.566-8.68 3.773-12.113.32v2.332c0 .382-.38.693-.748.693s-.797-.31-.797-.693v-4.156c-.001-.299.183-.565.456-.66l4-1.385c.35-.12.79.086.906.45.117.363-.064.815-.414.936l-2.634 1.013c1.389 1.576 2.96 2.356 5.018 2.357 4.05 0 7.162-3.352 7.163-7.56 0-.382.362-.692.73-.692zM15.27 0c.368 0 .797.31.797.693v4.156a.691.691 0 0 1-.456.659l-4 1.385c-.35.121-.79-.085-.906-.449-.116-.363.064-.815.414-.936l2.634-1.013c-1.389-1.576-2.96-2.357-5.018-2.357-4.05-.001-7.162 3.351-7.162 7.56 0 .382-.363.692-.73.692-.369 0-.776-.31-.776-.693C.063 7.358.844 5.025 2.41 3.344c3.323-3.566 8.68-3.772 12.113-.32V.693c0-.383.38-.693.748-.693z"/>
              </svg>
              <span>{t.collaborate}</span>
            </Button>
          }
          <CollaborationRequestModal
            visible={requestModalShown}
            defaultEmail={defaultEmail}
            defaultInitiativeCohorts={cohorts || []}
            defaultIsWorkingHours={guessIsWorkingHours(start_time, end_time)}
            startTime={start_time}
            endTime={end_time}
            onClose={closeModals}
            onSave={submitRequestModal}
            autoApproved={auto_approved_collaborations}
            considerWorkingHours={showWorkingHours}
          />
        </>
      )
  }
};

export default injectIntl(Collaboration);
