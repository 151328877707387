import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import {
  Modal,
  Row,
  Col,
  Select,
  Input,
  Switch,
} from 'antd';

import T from 'components/T';

import {
  formatShort,
} from 'utils/date';

const EMAIL_REGEXP = new RegExp('^[a-z0-9._%+-]{1,64}@(?:[a-z0-9-]{1,63}.){1,125}[a-z]{2,63}$');

const CollaborationRequestModal = ({
  intl,
  onClose,
  onSave,
  visible,
  defaultEmail,
  defaultInitiativeCohorts,
  defaultIsWorkingHours,
  startTime,
  endTime,
  taxonomies,
  initiatives,
  firstTime = true,
  autoApproved = false,
  considerWorkingHours = false,
}) => {
  const t = intl.messages;
  const [ email, setEmail ] = useState(defaultEmail);
  const [ initiativeCohorts, setInitiativeCohorts ] = useState(defaultInitiativeCohorts);
  const [ isWorkingHours, setIsWorkingHours ] = useState(defaultIsWorkingHours);

  const {
    cohorts,
  } = taxonomies;

  const handleEmailChange = useCallback((e) => {
    e.preventDefault();
    setEmail((e.target.value || '').toLowerCase().trim());
  }, [ setEmail ]);

  const submitRequestModal = useCallback(() => {
    onSave(
      (email || '').trim(),
      initiativeCohorts,
      isWorkingHours,
    );
  }, [
    onSave,
    email,
    initiativeCohorts,
    isWorkingHours,
  ]);

  return (
    <Modal
      centered
      visible={visible}
      title={
        firstTime
        ? (
            autoApproved
            ?  t.collaboration_establish_title
            : t.collaboration_request_title
          )
        : t.collaboration_update_title
      }
      cancelText={ t.cancel }
      okText={
        firstTime
        ? (
            autoApproved
            ?  t.collaboration_send_establish
            : t.collaboration_send_request
          )
        : t.collaboration_send_update
      }
      okType= 'primary'
      onCancel={onClose}
      onOk={submitRequestModal}
      okButtonProps={{
        disabled: firstTime && !EMAIL_REGEXP.test(email)
      }}
    >
      <p>
        {
          firstTime
          ? (
              autoApproved
              ?  t.collaboration_establish_desc
              : t.collaboration_request_desc
            )
          : t.collaboration_update_desc
        }
      </p>
      <p>{ t.collaboration_enter_email }</p>
      <Input
        placeholder={ t.email }
        value={email}
        onChange={handleEmailChange}
        disabled={!firstTime}
      />
      { !cohorts || cohorts.length === 0 ? null :
        <React.Fragment>
          <div className="Whitespace20"></div>
          <p>{ t.collaboration_enter_groups }</p>
          <Row type="flex">
            <Select
              placeholder={ t.groups }
              value={initiativeCohorts}
              onChange={(change) => setInitiativeCohorts(change)}
              style={{ flex: 1 }}
              mode="multiple"
            >
              {cohorts.map(cohort => (
                <Select.Option key={cohort.slug} value={cohort.slug}>{ cohort.name }</Select.Option>
              ))}
            </Select>
          </Row>
        </React.Fragment>
      }
      { !considerWorkingHours ? null :
        <React.Fragment>
          <div className="Whitespace20"></div>
          <p>
            <T
              collaboration_working_hours={{
                start: formatShort(startTime, intl),
                end: formatShort(endTime, intl),
              }}
            />
          </p>
          <Row type="flex">
            <Col
              xs={16}
              md={12}
            >
              { t.during_working_hours_question }
            </Col>
            <Col
              xs={8}
              md={12}
            >
              <Switch
                checkedChildren={t.yes}
                unCheckedChildren={t.no}
                checked={isWorkingHours}
                onChange={setIsWorkingHours}
              />
            </Col>
          </Row>
        </React.Fragment>
      }
    </Modal>
  );
};

const mapStateToProps = ({
  taxonomies,
  initiatives,
}) => ({
  taxonomies,
  initiatives,
});

export default injectIntl(connect(mapStateToProps, {})(CollaborationRequestModal));

